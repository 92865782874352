<template>
	<SideOver @onClose="() => onClose()" :is-show="isShow" :title="title" :label="content" :isLoading="isLoading"
						:buttons="getButtons()">
		<swiper v-if="showSwiper" @swiper="setSwiper" :simulateTouch="false" :modules="[Controller]"
						:controller="{ control: swiper }" :slides-per-view="1"
						:spaceBetween="10" :initialSlide="activeIndex && parseInt(activeIndex)" class='px-2 max-w-lg'>
			<swiper-slide class='relative'>
				<div class="flex-1 h-full p-6 space-y-5 overflow-y-auto">
					<Input type="text" v-model:value="formObject.name"
								 placeholder="i.e. Adults in physical therapy OR Millenial moms" label="Name *"/>
					<Select placeholder="" label="Type *" :items="options" :selected-item="selectedItem" custom-class="w-full"
									help-text="This will help determine how to research them and whether your potential business in B2B, B2C, etc."/>
					<div class="pt-2">
						<Text size="lg" weight="medium" color="black" content="Research Prompt"/>
						<Text size="xs" color="gray-400" custom-class="italic"
									content="What are you hoping to learning about this Target Customer? Your research prompt will help generate content for your interviews and surveys."/>
					</div>
					<div>
						<Text weight="semibold" size="sm" color="gray-700" custom-class="block"
									v-html="`We are learning about the real experiences of <code class='bg-gray-100'>${formObject.name ? formObject.name: ''}</code> and how they...`"/>
						<Input type="text" v-model:value="formObject.promptText"
									 placeholder="i.e. rehab from surgery OR file their taxes"/>
					</div>
				</div>
			</swiper-slide>
			<swiper-slide class='relative'>
				<div class="flex-1 h-full p-6 space-y-5 overflow-y-auto">
					<div>
						<Text size="lg" weight="medium" color="black" content="Demographics, Interests, and Activities"/>
						<Text size="xs" color="gray-400" custom-class="italic"
									content="Think about the bare minimum descriptors that will define who is in your Target Customer segment and who is not. If you select more than one, you are saying MOTH can be in your Target Customer"/>
					</div>
					<AutoComplete label="Age (i.e. ONLY adults, or ONLY 55-64 years old)"
												:listData="ageList" placeholder="Start typing to filter"
												:handleSelectFn="(val) => {formObject.age = val;}"/>
					<AutoComplete label="Gender (i.e. ONLY women or ONLY non-binary)" :listData="genderList"
												placeholder="Start typing to filter" :handleSelectFn="(val) => {formObject.gender = val;}"/>
					<AutoComplete label="Race/Enthicity (i.e. ONLY of Hispanic, LatinX, or Spanish origin)"
												:listData="ethnicityList"
												placeholder="Start typing to filter" :handleSelectFn="(val) => {formObject.ethnicity = val;}"/>
					<AutoComplete label="Income (i.e. ONLY low income)" :listData="incomeList"
												placeholder="Start typing to filter" :handleSelectFn="(val) => {formObject.income = val;}"/>
					<MultiSelectDropdown label="Location" :options="locationsList" v-model:selected="formObject.location"/>
					<div>
						<MultiSelectDropdown label="Interests and Activities" :options="interestsList"
																 v-model:selected="formObject.interests"/>
						<Text size="xs" color="gray-400" custom-class="italic mt-1"
									:content="`If an interest or activity isn't on the list, you can type it in and hit &ldquo;enter&rdquo; and it will be added as a new tag.`"/>
					</div>
				</div>
			</swiper-slide>
		</swiper>
	</SideOver>
</template>

<script>
import Text from '../../../atoms/Text/Text.vue'
import Icon from '../../../atoms/Icons/Icons.vue'
import Input from '../../../molecules/Inputs/Component/Component.vue'
import Select from '../../../molecules/Selects/Component/Component.vue'
import Button from '../../../atoms/Button/Button.vue'
import SideOver from '../../SideOvers/WithBackgroundOverlay/WithBackgroundOverlay.vue'
import AutoComplete from '../../../molecules/AutoComplete/AutoComplete'
import MultiSelectDropdown from "../../../molecules/Dropdown/MultiSelectDropdown/MultiSelectDropdown";
import {ref} from 'vue';
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {Controller} from 'swiper';
import 'swiper/swiper.min.css';

export default {
	components: {
		Text,
		Icon,
		Input,
		Select,
		Button,
		SideOver,
		AutoComplete,
		MultiSelectDropdown,
		Swiper,
		SwiperSlide
	},

	props: {
		isShow: {
			type: Boolean,
			default: true
		},
		title: {
			type: String,
			default: ''
		},
		content: {
			type: String,
			default: ''
		},
		button: {
			type: Object,
			default: () => {
			}
		},
		isLoading: {
			type: Boolean,
			default: true
		},
	},

	data() {
		const swiper = ref(null);
		const setSwiper = (e) => {
			swiper.value = e;
		};
		return {
			show: false,
			options: [
				{id: 0, name: 'Individuals'}
			],
			selectedItem: {},
			setSwiper,
			Controller,
			swiper,
			activeIndex: 0,
			showSwiper: false,
			formObject: {},
			ageList: [
				{id: 1, label: '20-25 years'},
				{id: 2, label: '25-30 years'},
				{id: 3, label: '30-35 years'},
				{id: 4, label: '35-40 years'},
			],
			genderList: [
				{id: 1, label: 'Male'},
				{id: 2, label: 'Female'}
			],
			ethnicityList: [
				{
					id: 1,
					label: 'Asian'
				},
				{
					id: 2,
					label: 'Asian British'
				},
				{
					id: 3,
					label: 'Spanish'
				}
			],
			incomeList: [
				{
					id: 1,
					label: 'income 1'
				},
				{
					id: 2,
					label: 'income 2'
				}
			],
			locationsList: [
				'USA',
				'Mexico',
				'UK'
			],
			interestsList: [
				'Travelling',
				'Net Surfing',
				'Reading'
			]
		}
	},
	watch: {
		isShow: function (val) {
			if (val) {
				setTimeout(() => this.showSwiper = true, 200)
			}
		}
	},
	methods: {
		onClose() {
			this.$emit('onClose');
			setTimeout(() => this.showSwiper = false, 500)
		},
		onNext(index) {
			this.swiper.slideTo(index + 1);
			this.activeIndex = index + 1;
		},
		onBack(index) {
			this.swiper.slideTo(index - 1);
			this.activeIndex = index - 1;
		},
		getButtons() {
			if (this.activeIndex === 0) {
				return [
					{
						label: 'Cancel',
						type: 'secondary',
						isLoading: false,
						action: () => this.$emit('onClose')
					},
					{
						label: 'Next',
						type: 'primary',
						isLoading: false,
						action: () => this.onNext(1)
					}
				]
			} else {
				return [
					{
						label: 'Back',
						type: 'secondary',
						isLoading: false,
						action: () => this.onBack(1)
					},
					{
						label: 'Save',
						type: 'primary',
						isLoading: false,
						action: () => this.onSave(0)
					}
				]
			}
		},
		onSave(index) {
			this.onNext(index);
			if (this.formObject.name && Object.keys(this.selectedItem).length > 0) {
				this.actions.alert.showSuccess({message: 'data saved successfully!'});
				this.$emit('onClose');
			} else {
				//show error message
			}


		}
	},
	mounted() {
		setTimeout(() => {
			this.show = this.isShow;
		}, 50);
	},
}
</script>
