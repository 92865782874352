<template>
 <ListViewTemplate
    view-name="targetCustomer"
    :items="targetCustomers"
    :is-loading="isLoading"
    :pageTotalRecords="state.targetCustomer.totalRecords"
    :recordActivePage="state.targetCustomer.activePage"
    :recordPerPage="state.targetCustomer.targetCustomerPerPage"
    :onClickItem="(item) => onClickItem(item)"
    :getPageData= "getTargetCustomersData"
    :toggleSlideover="(val) => isShow = val"
    :listHeader="targetCustomerHeader"
    :is-show="isShow"
    :emptyViewBinding="emptyViewBinding"
  >
    <template v-slot:add>
       <AddTargetCustomer @onClose="() => isShow=false" :is-show="isShow" v-bind="c.content.addItem"/>
    </template>
  </ListViewTemplate>
</template>

<script>
import ListViewTemplate from "@/components/organisms/ListViewItem/ListViewItem";
import AddTargetCustomer from "@/components/organisms/Modals/AddTargetCustomer/AddTargetCustomer";
import {c} from '@/components/constants'
export default {
  components: {
    ListViewTemplate,
    AddTargetCustomer
  },
  data() {
    return {
      isLoading: true,
      isShow: false,
      targetCustomerHeader: c.content.listHeader,
      c,
      emptyViewBinding: {
        title: 'Get started by adding your first target customer!',
        description: 'Once your add a customer, it will show up here',
        btnText: '+ Add Customer',
        icon: 'UsersIcon'
      }
    }
  },
    methods: {
    onClickItem(targetCustomer) {
      this.$router.push({name: 'Target Customers Detail', params: {id: targetCustomer.id}})
    },
    async saveTargetCustomer() {
    },
    async getTargetCustomersData() {
      this.isLoading = true;
      await this.actions.targetCustomer.getTargetCustomers();
      console.log(this.state.targetCustomer.targetCustomers, 'targetCustomers')
      this.targetCustomers = _.map(this.state.targetCustomer.targetCustomers, targetCustomer => {
        return {
          id: targetCustomer.id,
          title: targetCustomer?.id || '',
          description: targetCustomer?.type || '',
          actionLabel: targetCustomer?.status || 'Motivator',
          subtitles: [
            {
              icon: "MapIcon",
              name: "Tags",
              count: targetCustomer?.tags?.length
            },
            // {
            //   icon: "ExclamationIcon",
            //   name: "Target Customer User",
            //   count: targetCustomer?.targetCustomerUser?.length
            // },
            {
              icon: "UsersIcon",
              name: "Target Customer User",
              count: targetCustomer?.targetCustomerUser?.length
            },
          ],
        }
      });
      this.isLoading = false;
    }
  },
   async created() {
    await this.getTargetCustomersData();
  },
}
</script>

<style scoped>

</style>
